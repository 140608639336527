<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Blog & Press
            <v-spacer></v-spacer>
            <v-flex md2>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="800px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="green"
                    width="100%"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Blog</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <div id="app">
                              <vue-editor v-model="text"></vue-editor>
                            </div>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="title"
                              label=" Title"
                              :rules="Rules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="category"
                              :items="blogCategories"
                              item-text="name"
                              item-value="_id"
                              color="#B1B1B1"
                              placeholder="Blog Categories"
                              label="Blog Categories"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  label="Select Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="date" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="menu = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              @stepper="winStepper"
                              :height="'804'"
                              :width="'1195'"
                              :heading="'Upload Blog Image (Aspect Ratio: 3/2)'"
                              :componentType="'blogImage'"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              @stepper="winStepper"
                              :height="'600'"
                              :width="'2500'"
                              :heading="'Upload Blog Cover Image (Aspect Ratio: 4/1)'"
                              :componentType="'blogCoverImage'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
            <!-- <v-flex>
              <v-dialog
                :retain-focus="false"
                v-model="dialog1"
                persistent
                max-width="600px"
                :key="dialog1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Change Title
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addslider">
                    <v-card-title>
                      <span class="headline">Change Blog Title</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="blogtitle"
                              label="Blog Title"
                              :rules="titleRule"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="blogsubtitle"
                              label="Blog Subtitle"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-textarea
                              outlined
                              v-model="blogcaption"
                              label="Blog Caption"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog1 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="titleAdd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>
            <v-flex>
              <v-btn outlined color="red" href="/blogDeleted"
                >Deleted List</v-btn
              >
            </v-flex> -->
          </v-card-title>
          <div v-if="user.length > 0">
            <v-layout wrap pb-10>
              <v-flex v-for="(item, i) in user" :key="i" md3 pa-4>
                <v-card style="line-height: 16px" tile flat>
                  <div v-viewer style="display: flex">
                    <img
                      style="object-fit: contain; cursor: pointer; width: 100%"
                      :src="baseURL + item.image"
                      height="300px"
                    />
                  </div>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <!-- </v-img> -->
                  <v-layout wrap justify-center px-2>
                    <v-flex pt-2 xl12 sm12 md12 lg12 xs12>
                      <router-link
                        style="text-decoration: none"
                        :to="{
                          path: '/blogDetails/' + item._id,
                        }"
                      >
                        <v-col
                          cols="12"
                          md="12"
                          style="
                            font-size: 13px;
                            color: #000000;
                            text-decoration: none;
                          "
                        >
                          Title: <span>{{ item.title }}</span>
                        </v-col>
                      </router-link>
                      <v-col
                        v-if="item.blogdate"
                        cols="12"
                        md="12"
                        style="font-size: 13px; color: #000000"
                      >
                        Date:
                        <span>{{ item.blogdate.slice(0, 10) }}</span>
                      </v-col>
                    </v-flex>
                    <v-flex pt-5 pb-2 xl6 xs6 sm6 md6 lg6 px-5 justify-center>
                      <v-dialog v-model="item.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            outlined
                            block
                            class="mr-2"
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Delete
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this
                            Blog?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <br />
                    </v-flex>
                    <v-flex pt-5 pb-2 xl6 xs6 sm6 md6 lg6 px-5 justify-center>
                      <v-btn
                        small
                        outlined
                        block
                        class="mr-2"
                        color="green"
                        @click="editSlider(item)"
                      >
                        Edit
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
          <div v-else>
            <v-layout pt-15 justify-center fill-height wrap>
              <v-flex text-center xs12 lg12>
                <span style="font-size: 22px; letter-spacing: 1px">
                  No Data Found...
                  <br />
                  Try adding Data.
                </span>
              </v-flex>
            </v-layout>
          </div>
          <v-dialog v-model="editdialog" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Blog</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <div id="app">
                      <vue-editor v-model="user1.text"></vue-editor>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="user1.title"
                      label=" Title"
                      :rules="Rules"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md7>
                    <v-select
                      v-model="user1.category"
                      :items="blogCategories"
                      item-text="name"
                      item-value="_id"
                      class="formClass"
                      color="#B1B1B1"
                      placeholder="Blog Categories"
                      label="Blog Categories"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 md4 offset-1>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="user1.blogdate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user1.blogdate"
                          label="Select Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user1.blogdate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(user1.blogdate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 md12 lg12>
                    <ImageComp
                      :singleImage="user1.image"
                      :pageId="user1._id"
                      @stepper="winStepper"
                      :height="'804'"
                      :width="'1195'"
                      :heading="'Upload Blog Image (Aspect Ratio: 3/2)'"
                      :componentType="'blogImage'"
                    />
                  </v-flex>
                  <v-flex xs12 md12 lg12>
                    <ImageComp
                      :singleImage="user1.topImage"
                      :pageId="user1._id"
                      @stepper="winStepper"
                      :height="'600'"
                      :width="'2500'"
                      :heading="'Upload Blog Top Image (Aspect Ratio: 4/1)'"
                      :componentType="'blogCoverImage'"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="edit(user1._id)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <v-layout wrap justify-center>
          <v-flex xs12 text-center align-self-end v-if="pages > 1">
            <v-pagination
              v-model="currentPage"
              :length="pages"
              :total-visible="7"
              color="#FF3434"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
    VueEditor,
  },
  data() {
    return {
      ServerError: false,
      widthOfCard: "250px",
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      caption: "",
      title: "",
      category: null,
      blogImage: null,
      blogCoverImage: null,
      showsnackbar: false,
      msg: null,
      pages: 0,
      link: "",
      text: null,
      blogtitle: null,
      blogsubtitle: null,
      blogcaption: null,
      appLoading: false,
      currentPage: 1,
      count: 12,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      dialog1: false,
      editdialog: false,
      editingitem: [],
      user: [],
      user1: [],
      data: [],
      addslider: false,
      imgId: "",
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
      titleRule: [(v) => v.length < 50 || "Min 50 characters"],
      blogCategories: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getTitle();
    this.getCategories();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "blogImage") {
        this.blogImage = window_data.selectedFiles;
      }
      if (window_data.type == "blogCoverImage") {
        this.blogCoverImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/newblog/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDataNew(id) {
      // this.appLoading = true;
      axios({
        url: "/home/newblog/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user1 = response.data.data;
          this.user1.blogdate = this.user1.blogdate.slice(0, 10);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getTitle() {
      this.appLoading = true;
      axios({
        url: "/home/titles/get",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data.blog;
          this.blogtitle = this.data.title;
          this.blogsubtitle = this.data.subtitle;
          this.blogcaption = this.data.caption;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategories() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/newblog/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.blogCategories = response.data.data;
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    add() {
      var data = {};
      data["text"] = this.text;
      data["title"] = this.title;
      data["blogdate"] = this.date;
      data["category"] = this.category;
      axios({
        url: "/home/newblog/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;

          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.blogImage) this.uploadImage(response.data.id);
            if (this.blogCoverImage) this.uploadCoverImage(response.data.id);

            this.uploadImage(response.data.id);

            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.link = null;
            this.title = null;
            this.caption = null;
            this.date = null;
            this.imagePreview = null;
            this.file = null;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["text"] = this.user1.text;
      user["title"] = this.user1.title;
      user["blogdate"] = this.user1.blogdate;
      user["category"] = this.user1.category;
      user["id"] = this.user1;
      axios({
        method: "POST",
        url: "/home/newblog/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.blogImage) {
              this.uploadImage(this.user1._id);
            }
            if (this.blogCoverImage) {
              this.uploadCoverImage(this.user1._id);
            } else {
              this.getData();
              // this.$router.push("/home/blog");
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/newblog/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    editSlider(item) {
      this.getDataNew(item._id);
      this.editingitem = item;
      this.editdialog = true;
      this.editingitem.blogdate = this.editingitem.blogdate.slice(0, 10);
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.blogImage);

      axios({
        method: "POST",
        url: "/home/newblog/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.blogImage = null;

            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadCoverImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.blogCoverImage);

      axios({
        method: "POST",
        url: "/home/newblog/uploadtopimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.blogCoverImage = null;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
